.menu-enter {
  width: 65px !important;
}

.menu-enter-active {
  width: 240px !important;
  transition: width 300ms ease-in-out;
}

.menu-exit {
  width: 240px !important;
}

.menu-exit-active {
  width: 65px !important;
  transition: width 300ms ease-in-out;
}

.mobile-menu-enter {
  transform: translateX(-240px) !important;
}

.mobile-menu-enter-active {
  transform: translateX(0) !important;
  transition: transform 300ms ease-in-out;
}

.mobile-menu-exit {
  transform: translateX(0) !important;
}

.mobile-menu-exit-active {
  transform: translateX(-240px) !important;
  transition: transform 300ms ease-in-out;
}

.opacity-enter {
  opacity: 0 !important;
}

.opacity-enter-active {
  opacity: 1 !important;
  transition: opacity 300ms ease-in-out;
}

.opacity-exit {
  opacity: 1 !important;
}

.opacity-exit-active {
  opacity: 0 !important;
  transition: opacity 300ms ease-in-out;
}

.submenu-enter {
  transform: translateY(-125px) !important;
}

.submenu-enter-active {
  transform: translateY(0) !important;
  transition: transform 300ms ease-in-out;
}

.submenu-exit {
  transform: translateY(0) !important;
}

.submenu-exit-active {
  transform: translateY(-125px) !important;
  transition: transform 300ms ease-in-out;
}

.finance-menu-se-enter {
  transform: translateY(-136px) !important;
}

.finance-menu-client-enter {
  transform: translateY(-56px) !important;
}

.finance-menu-enter {
  transform: translateY(-176px) !important;
}

.finance-menu-se-enter-active,
.finance-menu-client-enter-active,
.finance-menu-enter-active {
  transform: translateY(0) !important;
  transition: transform 300ms ease-in-out;
}

.finance-menu-se-exit,
.finance-menu-client-exit,
.finance-menu-exit {
  transform: translateY(0) !important;
}

.finance-menu-se-exit-active {
  transform: translateY(-136px) !important;
  transition: transform 300ms ease-in-out;
}

.finance-menu-client-exit-active {
  transform: translateY(-56px) !important;
  transition: transform 300ms ease-in-out;
}

.finance-menu-exit-active {
  transform: translateY(-176px) !important;
  transition: transform 300ms ease-in-out;
}

.tool-menu-enter {
  transform: translateY(-216px) !important;
}

.tool-menu-with-one-item-enter {
  transform: translateY(-56px) !important;
}

.tool-menu-enter-active,
.tool-menu-with-one-item-enter-active {
  transform: translateY(0) !important;
  transition: transform 300ms ease-in-out;
}

.tool-menu-exit,
.tool-menu-with-one-item-exit {
  transform: translateY(0) !important;
}

.tool-menu-exit-active {
  transform: translateY(-216px) !important;
  transition: transform 300ms ease-in-out;
}

.tool-menu-with-one-item-exit-active {
  transform: translateY(-56px) !important;
  transition: transform 300ms ease-in-out;
}

.analytics-menu-enter {
  transform: translateY(-136px) !important;
}

.analytics-menu-enter-active {
  transform: translateY(0) !important;
  transition: transform 300ms ease-in-out;
}

.analytics-menu-exit {
  transform: translateY(0) !important;
}

.analytics-menu-exit-active {
  transform: translateY(-136px) !important;
  transition: transform 300ms ease-in-out;
}

.education-menu-enter {
  transform: translateY(-96px) !important;
}

.education-menu-enter-active {
  transform: translateY(0) !important;
  transition: transform 300ms ease-in-out;
}

.education-menu-exit {
  transform: translateY(0) !important;
}

.education-menu-exit-active {
  transform: translateY(-96px) !important;
  transition: transform 300ms ease-in-out;
}

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.referral-enter {
  transform: translateY(125px) !important;
}

.referral-enter-active {
  transform: translateY(0) !important;
  transition: transform 300ms ease-in-out;
}

.referral-exit {
  transform: translateY(0) !important;
}

.referral-exit-active {
  transform: translateY(125px) !important;
  transition: transform 300ms ease-in-out;
}
